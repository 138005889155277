import { createContext } from 'react';

import IUser from '../types/IUser';

export interface IAuthContext {
  user?: IUser;
  isLoading: boolean;
  authHeader: string;
}

export default createContext<IAuthContext>({
  isLoading: false,
  authHeader: '',
});
