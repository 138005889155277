import React, { useLayoutEffect, useState, useEffect } from 'react';
import auth0TokenManager, { TokenManager } from '@digitools/auth0-token-manager';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import AuthContext, { IAuthContext } from '../contexts/AuthContext';
import IUser from '../types/IUser';
import { getEnvVariable } from '../utils/EnvUtils';
import useUserApi, { updateUserLastLogin } from '../hooks/useUserApi';

const audience = getEnvVariable('auth0Audience');
const clientID = getEnvVariable('auth0ClientId');
const domain = getEnvVariable('auth0Domain');
const auth0RedirectUri = getEnvVariable('auth0RedirectUri');
const auth0UserMetadataUrl = getEnvVariable('auth0UserMetadataUrl');

// TODO - eventually these shouldn't need to be configurable.
// Enabling overrides to incrementally turn-on auth0 for different apps.
export interface IAuth0ProviderProps {
  redirectUri?: string;
  redirectPathname?: string;
  successFallbackUrl?: string;
}

const Auth0Provider: React.FC<IAuth0ProviderProps> = ({
  children,
  redirectUri = auth0RedirectUri,
  redirectPathname = '/callback',
  successFallbackUrl = '/',
}) => {
  const location = useLocation();
  const history = useHistory();

  const { getMyProfile } = useUserApi();
  const [tokenManager, setTokenManager] = useState<TokenManager>();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<IUser>();
  const [authHeader, setAuthHeader] = useState('');

  const init = async () => {
    setIsLoading(true);

    const newTokenManager: TokenManager = await auth0TokenManager.init({
      audience,
      clientID,
      domain,
      redirectUri,
      scope: 'openId profile email',
    });

    let parseHashFailed = false;

    if (location.pathname === redirectPathname && !!location.hash) {
      try {
        await newTokenManager!.handleAuthentication();
      } catch (_) {
        parseHashFailed = true;
        // need a window assign here, as we are redirecting across apps
        window.location.assign('/public/unauthorized');
      }
    }

    if (newTokenManager.isAuthenticated()) {
      try {
        const redirectUrl = sessionStorage.getItem('redirectUri');
        const idToken = await newTokenManager!.getProfile();
        const newAuthHeader = await newTokenManager!.getAuthHeader();
        const profile = (idToken as any)[auth0UserMetadataUrl];

        // augment profile with applicationPermissions because it is no longer on the idToken
        const userData = (await getMyProfile(newAuthHeader)).data;
        profile.applicationPermissions = userData.userMetadata.internalUserPermission.internalApplicationPermissions;

        const loginTimestamp: number = (idToken as any).auth_time * 1000;
        try {
          await updateUserLastLogin(newTokenManager.getAuthHeader(), profile.loginId, {
            lastLogin: new Date(loginTimestamp).toISOString().replace('T', ' '),
          });
        } catch (e) {
          console.error(e, `Failed to update Internal User Last Login for ${profile.loginId}`);
        }

        if (!!redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push(successFallbackUrl);
        }

        sessionStorage.removeItem('redirectUrl');
        setUser(profile as IUser);
        setAuthHeader(newAuthHeader);
        setTokenManager(newTokenManager);
        setIsLoading(false);
      } catch (_) {
        // If something goes wrong here, they likely have an active Auth0 session with
        // the external app, but not the internal app.
        sessionStorage.setItem('redirectUrl', location.pathname);

        // TODO: Need to update authorize options interface to be aware of prompt
        // @ts-ignore
        newTokenManager!.authorize({ connection: 'lfg-adfs', prompt: 'login' });
      }
    }
    
    if (!newTokenManager.isAuthenticated() && !parseHashFailed) {
      sessionStorage.setItem('redirectUrl', location.pathname);

      // TODO: Need to update authorize options interface to be aware of prompt
      // @ts-ignore
      newTokenManager!.authorize({ connection: 'lfg-adfs', prompt: 'login' });
    }
  };

  useLayoutEffect(() => {
    init();
  }, []);

  const value: IAuthContext = {
    isLoading,
    user,
    authHeader,
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading && <LoadingScreen />}
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export default Auth0Provider;
