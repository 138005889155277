import { useReducer, useEffect } from 'react';
import axios from 'axios';
import useAuth from './useAuth';

const dataFetchReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        data: undefined,
        isLoading: false,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const configureOptions = (opts: any, authHeader: any) => {
  const defaultHeaders = {
    Authorization: authHeader,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const configuredOptions = {
    ...opts,
  };

  if (opts.headers) {
    configuredOptions.headers = {
      ...defaultHeaders,
      ...opts.headers,
    };
  } else {
    configuredOptions.headers = defaultHeaders;
  }

  return configuredOptions;
};

function useGet({ url, options, initialFetch, success, failure }: IUseGetParams) {
  const { authHeader } = useAuth();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: initialFetch,
    error: null,
    data: null,
  });

  const fetchData = async (uri: string, opts = {}, handlers: any) => {
    const handleError = (e: any) => {
      if (handlers.failure) {
        handlers.failure(e);
      }
      dispatch({ type: 'FETCH_FAILURE', error: e });
    };

    const configuredOptions = configureOptions(opts, authHeader);
    dispatch({ type: 'FETCH_INIT' });
    try {
      const result = await axios.get(uri, configuredOptions);
      dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      if (handlers.success) {
        handlers.success(result.data);
      }
      return result;
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const handlers = {
      success,
      failure,
    };
    if (initialFetch && url) {
      fetchData(url, options, handlers);
    }
  }, [url]);

  const get = ({ url: newUrl, options: newOptions, success: newSuccess, failure: newFailure }: any = {}) => {
    const handlers = {
      success: newSuccess || success,
      failure: newFailure || failure,
    };

    return fetchData(newUrl || url, newOptions || options, handlers);
  };

  return {
    ...state,
    get,
  };
}

export interface IUseGetParams {
  url?: string;
  options?: any;
  initialFetch?: boolean;
  success?: () => any;
  failure?: () => any;
}

export default useGet;
