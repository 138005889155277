import { Icon } from '@digitools/honest-react';
import React from 'react';

import Styles from './Header.scss';
import Logo from './Lincoln_Financial_sm_w.svg';

export interface IHeaderProps {
  title?: string;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  title = 'My Lincoln Portal Administration',
}) => (
  <header className={Styles.header}>
    <a href={'/'}>
      <Logo className={Styles['header__logo']} style={{viewBox: '0 0 600 200'}}/>
    </a>
    <h1 className={Styles['header__title']}>{title}<sup>®</sup></h1>
    <a href={'/'} className={Styles['header__home-link']}>
      <Icon className='mt-0 mr-1' name='home' color='white' />
      <span className={Styles['header__home-text']}>Home</span>
    </a>
  </header>
);

export default Header;
