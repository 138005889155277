import { IEnvironmentConfig } from '../types/environment/IEnvironmentConfig';

// @ts-ignore
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
const baseAdminUrl = 'https://admin.mylincolnportal.com';
const baseCustomerUrl = 'https://www.mylincolnportal.com';
const baseDentalUrl = 'https://provider.mylincolnportal.com'

const ENVIRONMENT_VARIABLES: IEnvironmentConfig = {
  auth0Audience: {
    local: 'com.lfg.digital.portal.api.internal',
    dev: 'com.lfg.digital.portal.api.internal',
    test: 'com.lfg.digital.portal.api.internal',
    prod: 'com.lfg.digital.portal.api.internal',
  },
  // TODO: update with local & prod clientIds(?)
  auth0ClientId: {
    local: 'MSoIbKfssLbd5okr3ELFawBu14WyhN0s',
    dev: 'MSoIbKfssLbd5okr3ELFawBu14WyhN0s',
    test: 'MSoIbKfssLbd5okr3ELFawBu14WyhN0s',
    prod: 'A0cucwPoHscaJRzv3x8WpDN2EP0yo1dF',
  },
  // TODO: Update with local domain(?)
  auth0Domain: {
    local: 'auth.np-mylincolnportal.com',
    dev: 'auth.np-mylincolnportal.com',
    test: 'auth.np-mylincolnportal.com',
    prod: 'auth.mylincolnportal.com',
  },
  // TODO: Update with nonprod and prod urls
  auth0RedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/callback' : 'http://localhost:8090/callback',
    dev: 'https://admin.np-mylincolnportal.com/callback',
    test: 'https://admin.np-mylincolnportal.com/callback',
    prod: baseAdminUrl + '/callback',
  },
  // TODO: Update with local url(?)
  auth0UserMetadataUrl: {
    local: 'https://admin.np-mylincolnportal.com/userMetadata',
    dev: 'https://admin.np-mylincolnportal.com/userMetadata',
    test: 'https://admin.np-mylincolnportal.com/userMetadata',
    prod: 'https://admin.mylincolnportal.com/userMetadata',
  },
  userAppRedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/user/callback' : 'http://localhost:8090/user/callback',
    dev: 'https://admin.np-mylincolnportal.com/user/callback',
    test: 'https://admin.np-mylincolnportal.com/user/callback',
    prod: baseAdminUrl +'/user/callback',
  },
  userReportsAppRedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/reports/callback' : 'http://localhost:8090/reports/callback',
    dev: 'https://admin.np-mylincolnportal.com/reports/callback',
    test: 'https://admin.np-mylincolnportal.com/reports/callback',
    prod: baseAdminUrl + '/reports/callback',
  },
  documentAppRedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/document/callback' : 'http://localhost:8090/document/callback',
    dev: 'https://admin.np-mylincolnportal.com/document/callback',
    test: 'https://admin.np-mylincolnportal.com/document/callback',
    prod: baseAdminUrl + '/document/callback',
  },
  configAppRedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/config/callback' : 'http://localhost:8090/config/callback',
    dev: 'https://admin.np-mylincolnportal.com/config/callback',
    test: 'https://admin.np-mylincolnportal.com/config/callback',
    prod: baseAdminUrl + '/config/callback',
  },
  supportAppRedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/support/callback' : 'http://localhost:8090/support/callback',
    dev: 'https://admin.np-mylincolnportal.com/support/callback',
    test: 'https://admin.np-mylincolnportal.com/support/callback',
    prod: baseAdminUrl + '/support/callback',
  },
  externalCustomerPortal: {
    local: 'http://localhost:8091/customer/samlp/lfg-adfs',
    dev: 'https://www.np-mylincolnportal.com/customer/samlpv2/lfg-adfs',
    test: 'https://www.np-mylincolnportal.com/customer/samlpv2/lfg-adfs',
    prod: baseCustomerUrl + '/customer/samlpv2/lfg-adfs',
  },
  lmAdminUrl: {
    local: isIE11 ? 'http://10.0.2.2:8090' : 'http://localhost:8090',
    dev: 'https://admin.np-mylincolnportal.com',
    test: 'https://admin.np-mylincolnportal.com',
    prod: baseAdminUrl,
  },
  notificationsAppRedirectUri: {
    local: isIE11 ? 'http://10.0.2.2:8090/notifications/callback' : 'http://localhost:8090/notifications/callback',
    dev: 'https://admin.np-mylincolnportal.com/notifications/callback',
    test: 'https://admin.np-mylincolnportal.com/notifications/callback',
    prod: baseAdminUrl +'/notifications/callback',
  },
  dentalProviderPortalUri: {
    local: 'http://localhost:1337/dental/adfs',
    dev: 'https://provider.np-mylincolnportal.com/dental/adfs',
    test: 'https://provider.np-mylincolnportal.com/dental/adfs',
    prod: baseDentalUrl +'/dental/adfs',
  },
};

export default ENVIRONMENT_VARIABLES;
