import React from 'react';
import { Spinner } from '@digitools/honest-react';

const LoadingScreen = () => (
  <main >
    <Spinner isShown={true} />
  </main>
);

export default LoadingScreen;
