import axios from 'axios';
import { IUserPatch, IDentalUserPatch, ICustomer } from '../../../user/src/types/IUserSearchResult';
import { UserSearchParameter } from '../../../user/src/types/SearchParameter';
import useAuth from './useAuth';
import useGet from './useGet';

const internalAdminEndpoint = '/mlp-user-service/services/v1/internal-admin';
const nonMigratedEndpoint = '/mlp-user-service/services/v1/nonmigrated-users';
const dentalAuthServiceEndpoint = '/dpp-auth-service/users/search';

// If we implement paging we should move these to be function parameters
const pageNum = `0`;
const pageSize = `100`;

function useUserApi({ success, failure }: IUseUserParams = {}) {
  const { authHeader } = useAuth();
  const { get, data, isLoading, error } = useGet({ success, failure });

  function userSearch(searchText: string, searchParam: UserSearchParameter, filterStatus: string, customer: ICustomer | null) {
    const args = searchText.split(',');
    const parsedFilterStatus = filterStatus !== 'all' ? filterStatus : null;
    const customerId = customer == null ? null : customer.customerId;

    switch (searchParam) {
      case UserSearchParameter.EMAIL:
        return getUsersByEmailAddress(args[0], parsedFilterStatus, customerId);
      case UserSearchParameter.USERNAME:
        return getUsersByLoginId(args[0], parsedFilterStatus, customerId);
      default:
        if (args.length > 1) {
          return getUsersByLastNameAndFirstName(args[0], parsedFilterStatus, customerId, args[1].trim());
        }
        return getUsersByLastName(args[0], parsedFilterStatus, customerId);
    }
  }

  function getUsersByLastName(lastName: string, parsedFilterStatus: string | null, customerId: string | null) {
    return {
      MLP: axios.post(
        `${internalAdminEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          lastName: lastName,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      MLPNonMigrated: axios.post(
        `${nonMigratedEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          lastName: lastName,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      DPP: axios.post(
        `${dentalAuthServiceEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          lastName: lastName,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
    };
  }

  function getUsersByLastNameAndFirstName(lastName: string, parsedFilterStatus: string | null,  customerId: string | null, firstName?: string) {
    return {
      MLP: axios.post(
        `${internalAdminEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          lastName: lastName,
          firstName: firstName,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      MLPNonMigrated: axios.post(
        `${nonMigratedEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          lastName: lastName,
          firstName: firstName,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      DPP: axios.post(
        `${dentalAuthServiceEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          lastName: lastName,
          firstName: firstName,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
    };
  }

  function getUsersByEmailAddress(emailAddress: string, parsedFilterStatus: string | null, customerId: string | null) {
    return {
      MLP: axios.post(
        `${internalAdminEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          email: emailAddress,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      MLPNonMigrated: axios.post(
        `${nonMigratedEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          email: emailAddress,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      DPP: axios.post(
        `${dentalAuthServiceEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          email: emailAddress,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
    };
  }

  function getUsersByLoginId(loginId: string, parsedFilterStatus: string | null, customerId: string | null) {
    return {
      MLP: axios.post(
        `${internalAdminEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          loginId: loginId,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      MLPNonMigrated: axios.post(
        `${nonMigratedEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          loginId: loginId,
          isActive: parsedFilterStatus,
          customerId: customerId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
      DPP: axios.post(
        `${dentalAuthServiceEndpoint}?page=${pageNum}&size=${pageSize}`,
        {
          email: loginId,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        },
      ),
    };
  }

  function getAuditLog(loginId: string) {
    return get({
      url: `/mlp-user-service/services/v1/audit/${loginId}`,
    });
  }

  function customerSearch(
    searchText: string,
    successOverride: (() => any) | null,
    failureOverride: (() => any) | null,
  ) {
    const args = searchText.split(',');
    return get({
      url: `${internalAdminEndpoint}/customer/search/${args[0]}`,
      success: successOverride,
      failure: failureOverride,
    });
  }

  function userPatch(userLoginId: string, userChanges: Partial<IUserPatch>) {
    return axios.patch(`/mlp-user-service/services/v1/users/${userLoginId}`, userChanges, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
    });
  }

  function addInternalUser({ firstName, lastName, employeeId, emailAddress, phoneNumber }: any) {
    return axios.post(
      `/mlp-user-service/services/v1/users/internal-user`,
      {
        userMetadata: {
          firstName,
          lastName,
          employeeId,
          emailAddress,
          phoneNumber,
        },
      },
      {
        headers: {
          Authorization: authHeader,
        },
      },
    );
  }

  function getMyProfile(authHeaderOverride: string = ''): any {
    const options = authHeaderOverride && {
      headers: {
        Authorization: authHeaderOverride,
      },
    };

    return get({
      url: `/mlp-user-service/services/v1/users/my-profile`,
      options,
    });
  }

  function dentalUserPatch(userLoginId: string, userChanges: Partial<IDentalUserPatch>) {
    const dentalUserEndpoint = `/dpp-auth-service/users/${userLoginId}`;
    return axios.patch(dentalUserEndpoint, userChanges, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
    });
  }

  return {
    userSearch,
    addInternalUser,
    userPatch,
    customerSearch,
    getMyProfile,
    getAuditLog,
    dentalUserPatch,
    data,
    isLoading,
    error,
  };
}

export const updateUserLastLogin: any = (authHeader: string, userId: string, requestBody: ILastLogin) => {
  return axios.patch(encodeURI(`/mlp-user-service/services/v1/users/${userId}`), requestBody, {
    headers: {
      Authorization: authHeader,
      'Content-Type': 'application/json',
    },
  });
};

export interface IUseUserParams {
  success?: () => any;
  failure?: () => any;
}

export interface ILastLogin {
  lastLogin: string;
}

export default useUserApi;
